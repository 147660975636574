import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const Project = loadable(() => import("../components/project"));
const CountDetails = loadable(() => import("../components/countdetails"));
const Testimonials = loadable(() => import("../components/testimonials"));

const schema = {
    "@context": "http://schema.org/",
    "@type": "product",
    "image": "https://softtik.com/images/nft-collection/invest-section-6.webp",
    "name": "Top Play To Earn NFT Game Development Company",
    "description": "Softtik is a leading play-to-earn NFT game development company that is reshaping the gaming industry with the help of its high-end solutions and pro team.",
    "brand": "Softtik Technologies",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.7",
        "reviewCount": "107"
    }
}

export class PTwoENFTGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };

    render() {
        return (
            <>
                <InnerLayout header='Services' fallback={<Loader />}>
                    <Head
                        type="Adds"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/nft-game-development-services/"
                        title="NFT Game Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-6.webp"
                        description="Softtik is a leading play-to-earn NFT game development company that is reshaping the gaming industry with the help of its high-end solutions and pro team."
                    />
                    <main>
                        <div id="wrapper">
                            <div className="collection-page game-development-page">
                                {/* Banner */}
                                <section className="banner-sec services-banner-bg" id="book-an-appointment">
                                    <div className="banner-content">
                                        <div className="auto-container">
                                            <div className='row'>
                                                <div className="col-lg-8 col-md-12 col-sm-12 ">
                                                    <div className='banner-text wow fadeIn' data-wow-delay="0.3s">
                                                        <h1>NFT Game Development</h1>
                                                        <p>There Has Never Been<br />
                                                            A better Time to Game!<br />
                                                            Turn Your Character into a Digital Asset<br />
                                                            Create the Ultimate Gaming Experience<br />
                                                            Let Us Help You in NFT Game Development</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12 col-sm-12 ">
                                                    <div className='form-inner'>
                                                        <div className='form-area' style={{ zIndex: '0' }}>
                                                            <iframe src="https://contact-banner-softtik.gr8.com/"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='about-sec' id="about">
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='about-text col-lg-6 col-md-12'>
                                                    <div className='about-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='sec-title'>
                                                            <h2>Transform Your NFT Gaming Ideas into Reality With Us</h2>
                                                            <p>Softtik is a prominent name in the NFT-based game ecosystem development. Do you have plans to launch a blockchain gaming platform? Don't worry; we've got you covered. Want to make a significant difference in the gaming industry? Please include us. We prefer to develop the best gaming ecosystem with our game development gurus in this digital age. </p>
                                                            <p>All those hours spent fighting gangs or learning race tracks are gone as the gamer moves on to the next gaming adventure. Play to Earn NFTs have the potential to put the power back in the hands of the gamer. The Interesting highlight is that we are the pioneers in providing  NFT gaming solutions and services.</p>
                                                        </div>

                                                        <ul className="list-about style-two">
                                                            <li>
                                                                <span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/logo-icon.webp' alt='softtiktech Image' /></span>
                                                                <div className="text-box">

                                                                    <p>Our specialized professionals have the most up-to-date understanding of NFTs and the necessary development skills to create NFT gaming platforms with outstanding features. We're a well-known  NFT game development firm that develops and deploys solutions to help companies flourish.</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/logo-icon.webp' alt='softtiktech Image' /></span>
                                                                <div className="text-box">
                                                                    <p>Our  NFT gaming development services have a different leading image in the gaming market. Get secure by leveraging development solutions that encourage efficiency and transparency while protecting from hacks.</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/logo-icon.webp' alt='softtiktech Image' /></span>
                                                                <div className="text-box">
                                                                    <p>The gaming platform we provide will appeal to a broad audience of professional gamers and individuals with little knowledge of cryptocurrency and NFTs.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <p>Get in touch with us to make a difference in the gaming industry because we can help you with our high-end  NFT gaming development solutions.</p>
                                                    </div>
                                                </div>
                                                <div className='about-img col-lg-6 col-md-12'>
                                                    <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/blockchain-game-img.webp' alt="About Image" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Services */}
                                    <section className='services-sec p2egame-development' >
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='sec-title text-center'>
                                                        <h2>Our  NFT Game Development Services</h2>
                                                    </div>
                                                </div>
                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-6.webp' alt='NFT Collection' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>Complete Cycle NFT Game Development</h3>
                                                            <p>We have the right skill-set and experience to assist you in entering the gaming industry with our most satisfactory NFT gaming platform development. We can provide addictive and fun-to-play games that attract gamers worldwide.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner stye-two wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-7.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>2D/3D NFT Game Development</h3>
                                                            <p>Our trained and skilled team of developers can aid you in minting unique in-game 2D/3D NFTs. One of the most significant advantages of NFTs is that gamers do not need to rely on third-party payment methods to obtain game assets.</p>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-8.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>VR NFT Game Development</h3>
                                                            <p>VR has become a flag-bearer for the immersive and more realistic gaming experience. We can integrate VR compatibility with our leading NFT gaming platform development services. Get in touch with us to do wonders with this latest technology.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner stye-two wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-9.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>NFT Game Launchpad Development</h3>
                                                            <p>The popularity and importance of the launchpad have been increasing due to its efficient project listing and launching. We can develop a future-ready and high-end NFT game launchpad with our years of experience and knowledge.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-10.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>DAO Development </h3>
                                                            <p>DAO is the ultimate key solution to generating funding for the development of your  games. We can develop autonomous, reliable, and efficient DAOs that assist the community in taking back the control to make any changes in the game through DAO voting.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 text-center">
                                                    <Link to={`#book-an-appointment`} title="Get A Free Quote" className="stripped btn-style-four" stripHash>
                                                        Get A Free Quote
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                                {/* Cases */}
                                <section className='featured-area-sec p2e-features' >
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='sec-title text-center'>
                                                    <h2>Key Features Of A  NFT Game</h2>
                                                    <p> games are reshaping the gaming space by offering additional security, incentives, and fewer dependencies. You can boost your feasibility with Blockchain-based games by storing game characters and virtual things decentralized. Players can now collect lucrative in-game virtual items thanks to blockchain technology.</p>
                                                </div>

                                                <div className="featured-box wow fadeIn" data-wow-delay="0.3s">
                                                    <div className="img-box text-center">
                                                        <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/feature-img-5.webp' alt="Features of Our NFT Development" />
                                                        <div className="featured-block featured-block-7">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-35.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-1">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-34.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-2">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-36.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-3">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-37.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-4">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-38.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-5">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-41.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-6">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-42.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-8">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-39.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-9">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-40.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* Roadmap Section */}
                                <section id="roadmap" className="roadmap roadmap-ad-page">
                                    <div className="auto-container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="sec-title text-center">
                                                    <h2>Our  NFT Game Development Process</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="auto-container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="timeline">
                                                    <div className="timeline-container left">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-18.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>Business Consultation</h4>
                                                                    <p>We offer free business consultation to evaluate your project's objectives and expectations. Our specialists will examine your target audience and gaming genre in this session to provide the most up-to-date solutions.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    1
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container right">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-19.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>NFT Game Wireframe</h4>
                                                                    <p>We begin creating your product once you have agreed on the specifications and technologies. Creating a good roadmap for on-time delivery of your product is also part of this phase. We'll keep you informed about any anticipated changes.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    2
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container left">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-20.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>NFT Game Development</h4>
                                                                    <p>Our team will begin working on your project after a good wireframe and technology have been determined. We have the brightest and most certified developers that have years of expertise in developing blockchain games.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    3
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container right">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-21.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>Testing & Deployment</h4>
                                                                    <p>Once the development part of the project is completed, the testing phase will begin. Hundreds of tests are carried out to guarantee that the final product is bug-free and error-free. When the product is flawless, we will launch your  game.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    4
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container left ">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-22.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>Support & Maintenance</h4>
                                                                    <p>Our team of professionals and veterans from the gaming industry provides 24/7 support & maintenance to our clients. We will continue to support your project even after the launch. We don’t leave the side of our customers until the project is successful.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    5
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <Link to={`#book-an-appointment`} title="Book an Appointment" className="stripped btn-style-three mt-5" stripHash>
                                                    Book an Appointment
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </section >
                                <section className="banner-counter">
                                    <CountDetails />
                                </section>

                                {/* testimonials */}
                                <section className='testimonials-sec'>
                                    <Testimonials />
                                </section>

                                {/* Portfolio */}
                                <section className='portfolio-sec services-projects' >
                                    <Project />
                                </section>

                                {/* Technology */}
                                <section className='technology-sec ad-page-technology' id="technology">
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='technology-text col-lg-12 col-md-12'>
                                                <div className='technology-inner wow fadeIn text-center' data-wow-delay="0.3s">
                                                    <div className='sec-title'>
                                                        <h2>Technology Stack</h2>
                                                    </div>
                                                    <p>We ensure the highest quality development services & solutions by using a wide range of latest<br /> technologies. We’ve a certified and experienced team of developers that can provide cutting edge<br /> solutions by using this technology stack.</p>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-1.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-2.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-3.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-4.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-5.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-6.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='get-intouch-sec ad-page-get-intouch' id="get-intouch">
                                    <div className='get-intouch-secs'>
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='form-box col-lg-6 col-md-12'>
                                                    <div className='form-inner'>
                                                        <div className='sec-title text-center'>
                                                            <h2>Meeting with us</h2>
                                                            <p>Softtik has the legacy of offering perfectly-suited DEX solutions to clients worldwide. We are up-to-date with the latest trends in the market and know how to create a platform that provides value to our clients.  Book an appointment with us to discuss your idea; our experts will be happy to help you.</p>
                                                            <Link to={`#book-an-appointment`} title="Book FREE SESSION" className="stripped btn-style-four" stripHash>
                                                                Book FREE SESSION
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='calendly-box col-lg-6 col-md-12'>
                                                    <div className='calendly-inner'>
                                                        <div className='calendly-area'>
                                                            <iframe className="calendly-inline-widget" src="https://calendly.com/softtik-technologies?hide_gdpr_banner=1" loading="lazy" width="520px" height="630px"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default PTwoENFTGame;